import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import Page from '../templates/page'
// Components
import CheckLink from '../components/checkLink'
import Layout from '../components/layout'
import Seo from '../components/seo'

export const query = graphql`
  query page404 {
    prismicPage(uid: { eq: "404" }) {
      ...PrismicPageFragment
    }
  }
`

const NotFoundPage = ({ data }) => {
  const pageData = data.prismicPage.data
  return (
    <Layout>
      <Seo title={pageData.meta_title ? (pageData.meta_title):(pageData.title)} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      <div className='text-gray-800 text-lg'>
        <div className='relative'>
          {pageData.page_header.length === 1 && (
            <div className={`${pageData.page_header.[0].page_image.fluid ? ('relative bg-white overflow-hidden md:py-16 sm:py-16 component-hero'):('')}`}>
              {pageData.page_header !== null && pageData.page_header.[0].page_image.fluid && (
                <div className='md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                  <Img className='w-full h-full object-cover' fluid={pageData.page_header.[0].page_image.fluid} alt={pageData.page_header.[0].page_image.alt || ''} />
                </div>
              )}
              <div className={`${pageData.page_header.[0].page_image.fluid ? ('lg:px-10 md:pb-20 md:pt-16 relative xl:py-24 md:text-left'):('')} z-10 relative max-w-6xl mx-auto py-8 px-4 sm:px-6 text-center`}>
                <div className={`${pageData.page_header.[0].page_image.fluid ? ('md:w-1/2 md:pr-8'):('')}`}>
                  {pageData.page_header.[0].page_title && (
                    <h1 className='text-4xl tracking-tight font-serif md:text-5xl xl:text-6xl' dangerouslySetInnerHTML={{__html:pageData.page_header.[0].page_title.text}} />
                  )}
                  {pageData.page_header.[0].page_text && (
                    <div className={`z-10 relative mt-3 md:mt-5 prose prose-lg lg:prose-xl prose-blue ${pageData.page_header.[0].page_image.fluid ? (''):('min-w-full')} `} dangerouslySetInnerHTML={{__html:pageData.page_header.[0].page_text.html}} />
                  )}
                  {pageData.page_header.[0].page_button_link.url && (
                    <div className='mt-auto pt-3 md:pt-6'>
                      <CheckLink className={`${pageData.page_header.[0].page_button_text && ('button')}`} link={pageData.page_header.[0].page_button_link}>{pageData.page_header.[0].page_button_text}</CheckLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

      </div>
    </Layout>
  )
}

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    page: Page
  },
})
